<template>
  <div class="travelRouteOrder">
    <div class="main">
      <div class="left">
        <div class="title">
          <div>
            <span>出发地:</span>
            <span>{{ linDes.start_city_nm }}</span>
          </div>
          <div>
            <span>产品编号:</span>
            <span>{{ linDes.route_bn }}</span>
          </div>
        </div>

        <div class="info">
          <img :src="`${$imgBaseURL}${linDes.img}`" alt />
          <span>{{ linDes.route_nm }}</span>
        </div>

        <div class="date">出行日期</div>
        <div class="date">
          {{ data.route_beg_date | rTime }} ~ {{ data.route_end_date | rTime }}
        </div>
        <div class="go_back" @click="go_back">返回修改此行程？</div>

        <div class="roomList">
          <div v-for="(item, index) in data.offered_info" :key="index">
            <div>房间{{ index + 1 }}</div>
            <div v-html="getRommTypShow(item)"></div>
          </div>
        </div>

        <div class="price">
          <div>基础团费</div>
          <div>
            <span
              >{{ data.adult_num != 0 ? data.adult_num + '成人' : '' }}
              {{ data.adult_num != 0 ? data.children_num + '儿童' : '' }}</span
            >
            <span>{{ roomJson.currency_sign || '' }}{{ data.base_money }}</span>
          </div>
          <div>
            <span>应付总额</span>
            <span>{{ roomJson.currency_sign || '' }}{{ data.base_money }}</span>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="top">
          <div class="title">
            <span class="big-title">行程安排</span>
            <span class="sub-title">游玩线路随心改变</span>
          </div>

          <div class="schedule">
            <input type="text" placeholder="请输入你的行程安排" />
            <img src="@/assets/index/right.png" alt srcset />
          </div>

          <div class="title">
            <span class="big-title">上车地点</span>
            <span class="sub-title">游玩线路随心改变</span>
          </div>

          <div class="schedule" @click="dialogVisible = true">
            <span>请选择</span>
            <img src="@/assets/index/right.png" alt srcset />
          </div>
        </div>

        <div class="per_info">
          <div class="title">
            <span class="big-title">预定人信息</span>
            <span class="sub-title">用于接收订单消息</span>
          </div>
          <div class="input_box_list">
            <div>
              <div class="input_box">
                <div class="label">姓（拼音/英语）</div>
                <input
                  v-model="data.bill_user_surname"
                  type="text"
                  placeholder="请填写姓（拼音/英文）"
                />
                <span
                  class="prompt"
                  v-html="
                    data.bill_user_surname == '' && flag
                      ? '预定人姓不得为空'
                      : ''
                  "
                ></span>
              </div>
            </div>
            <div>
              <div class="input_box">
                <div class="label">名（拼音/英语）</div>
                <input
                  v-model="data.bill_user_name"
                  type="text"
                  placeholder="请填写名（拼音/英文）"
                />
                <span
                  class="prompt"
                  v-html="
                    data.bill_user_name == '' && flag ? '预定人名不得为空' : ''
                  "
                ></span>
              </div>
            </div>

            <div>
              <div class="input_box">
                <div class="label">性别</div>
                <select v-model="data.bill_user_sex">
                  <option value="1">男</option>
                  <option value="2">女</option>
                </select>
                <span
                  class="prompt"
                  v-html="
                    data.bill_user_sex == '' && flag ? '预定人性别不得为空' : ''
                  "
                ></span>
              </div>
            </div>
          </div>

          <div class="input_box_bottom">
            <div>
              <div class="input_box">
                <div class="label">邮箱</div>
                <input
                  v-model="data.bill_user_email"
                  type="text"
                  placeholder="请填写邮箱"
                />
                <span
                  class="prompt"
                  v-html="
                    data.bill_user_email == '' && flag
                      ? '预定人邮箱不得为空'
                      : ''
                  "
                ></span>
              </div>
            </div>

            <div>
              <div class="input_box phone_input_box">
                <div class="label">手机号码</div>
                <select v-model="data.bill_user_tel_cc" placeholder="区号">
                  <option
                    v-for="(item, index) in article"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></option>
                </select>
                <input
                  v-model="data.bill_user_tel"
                  type="text"
                  placeholder="请填写手机号码"
                />
                <span
                  class="prompt"
                  v-html="
                    data.bill_user_tel == '' && flag
                      ? '预定人手机号不得为空'
                      : ''
                  "
                ></span>
              </div>
            </div>
          </div>

          <div class="title">
            <span class="big-title">参团旅客信息</span>
            <span class="sub-title">请按房间填写资料</span>
            <span class="prompt"
              >填写的个人信息要与护照上的信息相同，第一位旅客会默认为旅途当日联系人，请填写手机号并保持电话畅通。</span
            >
          </div>

          <el-tabs type="border-card">
            <el-tab-pane
              v-for="(item, index) in data.offered_info"
              :key="index"
              :label="'房间' + (index + 1)"
            >
              <div
                v-for="(checkin_info, index) in item.checkin_info"
                :key="index"
              >
                <h5>
                  {{
                    checkin_info.adult_num == 1 ? '成人' + (index + 1) : '儿童1'
                  }}
                </h5>
                <div class="tourist_info">
                  <div>
                    <div class="input_box">
                      <div class="label">姓（拼音/英语）</div>
                      <input
                        v-model="checkin_info.checkin_surname"
                        type="text"
                        placeholder="请填写姓（拼音/英文）"
                      />
                      <span
                        class="prompt"
                        v-html="
                          checkin_info.checkin_surname == '' && flag
                            ? '姓不得为空'
                            : ''
                        "
                      ></span>
                    </div>
                  </div>
                  <div>
                    <div class="input_box">
                      <div class="label">名（拼音/英语）</div>
                      <input
                        v-model="checkin_info.checkin_name"
                        type="text"
                        placeholder="请填写名（拼音/英文）"
                      />
                      <span
                        class="prompt"
                        v-html="
                          checkin_info.checkin_name == '' && flag
                            ? '名不得为空'
                            : ''
                        "
                      ></span>
                    </div>
                  </div>

                  <div>
                    <div class="input_box">
                      <div class="label">性别</div>
                      <select v-model="checkin_info.checkin_sex">
                        <option value="男">男</option>
                        <option value="女">女</option>
                      </select>
                      <span
                        class="prompt"
                        v-html="
                          checkin_info.checkin_sex == '' && flag
                            ? '请选择性别'
                            : ''
                        "
                      ></span>
                    </div>
                  </div>

                  <div>
                    <div class="input_box">
                      <div class="label">生日</div>

                      <el-date-picker
                        v-model="checkin_info.checkin_birthday"
                        type="date"
                        placeholder="选择日期"
                      ></el-date-picker>

                      <span
                        class="prompt"
                        v-html="
                          checkin_info.checkin_birthday == '' && flag
                            ? '日期不得为空'
                            : ''
                        "
                      ></span>
                    </div>
                  </div>

                  <div>
                    <div class="input_box">
                      <div class="label">国籍</div>
                      <input
                        v-model="checkin_info.checkin_nationality"
                        type="text"
                        placeholder="请填写名国籍"
                      />

                      <span
                        class="prompt"
                        v-html="
                          checkin_info.checkin_nationality == '' && flag
                            ? '国籍不得为空'
                            : ''
                        "
                      ></span>
                    </div>
                  </div>

                  <div>
                    <div class="input_box">
                      <div class="label">护照</div>
                      <input
                        v-model="checkin_info.checkin_passport"
                        type="text"
                        placeholder="请填写名护照"
                      />
                      <span
                        class="prompt"
                        v-html="
                          checkin_info.checkin_passport == '' && flag
                            ? '护照不得为空'
                            : ''
                        "
                      ></span>
                    </div>
                  </div>

                  <div>
                    <div class="input_box phone_input_box">
                      <div class="label">手机</div>
                      <select v-model="checkin_info.checkin_tel_cc">
                        <option
                          v-for="(item, index) in article"
                          :key="index"
                          :label="item"
                          :value="item"
                        ></option>
                      </select>
                      <input
                        v-model="checkin_info.checkin_tel"
                        type="text"
                        placeholder="请填写手机"
                      />
                      <span
                        class="prompt"
                        v-html="
                          checkin_info.checkin_tel == '' && flag
                            ? '手机不得为空'
                            : ''
                        "
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="pay_info">
      <div>
        <el-checkbox v-model="checked"></el-checkbox>
        <span>
          我已认真阅读并同意游遍欧洲旅行网的&nbsp;&nbsp;&nbsp;&nbsp;
          <el-link :underline="false" @click="linkShow('1')" target="_blank"
            >客户协议</el-link
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <el-link :underline="false" @click="linkShow('2')" target="_blank"
            >修改取消条款</el-link
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <el-link :underline="false" @click="linkShow('3')" target="_blank"
            >使用和隐私条款</el-link
          >
        </span>
      </div>

      <div>
        <div @click="submitOrder" class="pay">
          {{ roomJson.currency_sign || ''
          }}{{ data.base_money }} 下一步，生成订单
        </div>
      </div>
    </div>
    <div style="height: 30px"></div>
    <el-dialog
      title="上车地点"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <span>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input
              disabled
              size="mini"
              v-model="data.geton_time"
              placeholder="上车时间"
            ></el-input>
          </el-col>
          <el-col :span="12">
            <el-input
              disabled
              size="mini"
              v-model="data.geton_city_nm"
              placeholder="上车城市"
            ></el-input>
          </el-col>
          <el-col :span="24">
            <el-input
              type="textarea"
              disabled
              size="mini"
              v-model="data.geton_place"
              placeholder="上车地点"
            ></el-input>
          </el-col>

          <el-col :span="24">
            <div style="padding-bottom: 15px; font-size: 18px">特殊要求</div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="item in special"
                :key="item.base_dic_code"
                :label="item.base_dic_name"
                >{{ item.base_dic_name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-col>

          <el-col :span="24">
            <div style="padding-bottom: 15px; font-size: 18px">其他需求</div>
            <el-input type="textarea" v-model="data.other_demand"></el-input>
          </el-col>
        </el-row>
      </span>
      <span slot="footer" class="dialog-footer">
        <div @click="dialogVisible = false" class="button">确认选择</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import article from '@/utils/article';
import {
  travelRouteDetail,
  orderInsert,
  orderSearchById,
  get_sp_demand,
} from '@/api/index';
export default {
  name: 'PersonalCenter',
  data() {
    return {
      dialogVisible: false,
      flag: false,
      article: article,
      checked: false,
      linDes: {},
      special: '',
      roomJson: JSON.parse(window.localStorage.getItem('roomJson')),
      data: {
        route_id: '',
        sp_demand: '',
        other_demand: '',
        route_s_img: '',
        route_beg_date: '',
        route_end_date: '',
        bill_user_tel_cc: 'China-中国-86',
        adult_num: 0,
        children_num: 0,
        base_money: 0,
        geton_city_bn: '',
        geton_city_nm: '',
        geton_place: '',
        geton_time: '',
        geton_detail_addr: '',
        is_advance_hotel: '1',
        is_late_hotel: '1',
        is_airport_pickup: '1',
        airport_pickup_flight: '',
        airport_pickup_time: '',
        is_transportation: '',
        transportation_flight: '',
        transportation_time: '',
        special_needs: '',
        bill_user_surname: '',
        bill_user_name: '',
        bill_user_sex: '',
        bill_user_email: '',
        bill_user_tel: '',
        bill_date: '',
        pay_status: '0',
        bil_status: '',
        opet_demo: '',
        offered_info: [
          {
            room_type: '',
            adult_num: 0,
            children_num: 0,
            checkin_info: [
              {
                adult_num: 0,
                checkin_surname: '',
                checkin_name: '',
                checkin_sex: '',
                checkin_birthday: '',
                checkin_nationality: '',
                checkin_passport: '',
                checkin_tel: '',
                checkin_tel_cc: 'China-中国-86',
              },
            ],
          },
        ],
        currency: 2,
      },
      checkList: [],
    };
  },
  computed: {
    ...mapState(['home']),
    currency() {
      return this.$store.state.home.currency.currency_id;
    },
    // 计算房间类型状态并返回
    getRommTypShow() {
      return function (param) {
        if (param) {
          let str = ``;
          if (param.room_type == '1人一房') {
            str = `
              <div class="line-1">
                <div class="line-1-1"></div>
                <div class="line-1-2">或</div>
                <div class="line-1-1"></div>
                <div class="line-1-1"></div>
              </div>
              `;
          }
          if (param.room_type == '2人一房') {
            str = `
              <div class="line-1">
                <div class="line-1-1"></div>
                <div class="line-1-2">或</div>
                <div class="line-1-1"></div>
                <div class="line-1-1"></div>
              </div>
              `;
          }
          if (param.room_type == '3人一房') {
            str = `
             <div class="line-1">
                <div class="line-1-1"></div>
                <div class="line-1-1"></div>
              </div>`;
          }
          if (param.room_type == '4人一房') {
            str = `
              <div class="line-1">
                <div class="line-1-1"></div>
                <div class="line-1-1"></div>
              </div>
            `;
          }

          let adult_num = 0;
          let children_num = 0;
          param.checkin_info.forEach((item) => {
            if (item.adult_num == 1) {
              adult_num++;
            } else {
              children_num++;
            }
          });

          let typeHtml = `
            <div>${adult_num + '成人'} ${
            children_num != 0 ? children_num + '儿童' : ''
          }</div>
          `;
          return str + typeHtml;
        }
      };
    },
  },
  created() {
    this.getLineDes();
  },

  mounted() {
    // 获取特殊需求
    get_sp_demand().then((res) => {
      this.special = res.data;
    });
  },

  filters: {
    rTime: function (value) {
      var json_date = new Date(value).toJSON();
      return new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, ' ')
        .replace(/\.[\d]{3}Z/, '')
        .substr(0, 10);
    },
  },
  watch: {
    currency: {
      handler(val) {
        this.data.currency = Number(val);
        this.getLineDes();
      },
      immediate: true,
    },
    checkList: {
      handler(val) {
        this.data.sp_demand = val.toString();
      },
      immediate: true,
    },
  },
  methods: {
    linkShow(val) {
      let text = this.$router.resolve({
        path: '/comm/HtmlPanel',
        query: {
          type: val,
        },
      });

      // 打开一个新的页面
      window.open(text.href, '_blank');
    },
    handleClose() {
      this.dialogVisible = false;
    },
    go_back() {
      this.$router.go(-1); //返回上一层
    },
    submitOrder() {
      let that = this;
      this.flag = true;

      if (
        this.data.bill_user_surname == '' ||
        this.data.bill_user_name == '' ||
        this.data.bill_user_sex == '' ||
        this.data.bill_user_email == '' ||
        this.data.bill_user_tel == ''
      ) {
        return false;
      }

      let array = this.data.offered_info;
      let flag_v = true;
      for (let i in array) {
        let oneFlag = true;
        let info = array[i];
        for (let key in info) {
          if (typeof info[key] != 'object') {
            if (info[key] == '' && typeof info[key] == 'string') {
              flag_v = false;
              break;
            }
          } else {
            for (let a in info[key]) {
              let infoObject = info[key][a];
              for (let key_a in infoObject) {
                if (
                  infoObject[key_a] == '' &&
                  typeof infoObject[key_a] == 'string'
                ) {
                  flag_v = false;
                  break;
                }
              }
              if (!flag_v) {
                break;
              }
            }
          }
          if (!flag_v) {
            break;
          }
        }

        if (!flag_v) {
          break;
        }
      }
      if (!flag_v) {
        return false;
      }
      // 添加用户id
      this.data.cur_user_id = this.home.userInfo.uid;
      this.data.bill_date = new Date().toISOString();
      if (this.checked) {
        orderInsert(this.data).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: '订单提交成功',
              type: 'success',
            });
            setTimeout(function () {
              that.$router.push({ path: '/' });
            }, 3000);
          }
        });
      } else {
        this.$message({
          message: '请选择您已阅读',
          type: 'warning',
        });
      }
    },
    getOrderJson() {
      // 计算得出线路订单信息
      // 1 当从线路详情页面进入时组成线路订单信息
      if (!this.$route.query.route_bn) {
        // 当前URL无线路编号，是从线路详情进入，通过详情信息组成订单信息
        let offered_info = [];
        this.roomJson.offered_info.forEach((item, index) => {
          offered_info.push({
            room_type: item.type,
            adult_num: Number(item.adultValue),
            children_num: Number(item.childValue),
            checkin_info: [],
          });

          if (item.adultValue != '') {
            for (let i = 0; i < Number(item.adultValue); i++) {
              offered_info[index].checkin_info.push({
                adult_num: 1,
                checkin_surname: '',
                checkin_name: '',
                checkin_sex: '',
                checkin_birthday: '',
                checkin_nationality: '',
                checkin_passport: '',
                checkin_tel: '',
                checkin_tel_cc: 'China-中国-86',
              });
            }
          }
          if (item.childValue != '') {
            for (let i = 0; i < Number(item.childValue); i++) {
              offered_info[index].checkin_info.push({
                adult_num: 0,
                checkin_surname: '',
                checkin_name: '',
                checkin_sex: '',
                checkin_birthday: '',
                checkin_nationality: '',
                checkin_passport: '',
                checkin_tel: '',
                checkin_tel_cc: 'China-中国-86',
              });
            }
          }
        });

        this.data.offered_info = offered_info;
        // 计算出行日期
        this.data.route_beg_date = new Date(this.roomJson.date);
        // this.data.route_end_date = this.transferCouponValueTime()
        this.transferCouponValueTime();
        // 成人数 儿童数
        this.getaAdultNum();
        // 基础团费
        this.data.base_money = this.roomJson.price;
        // 上车城市编号
        this.data.geton_city_bn = this.linDes.get_on_city;
        //上车城市名称
        this.data.geton_city_nm = this.linDes.get_on_city_nm;
        // 上车时间
        this.data.geton_time = this.linDes.get_on_time;
        // 上车地点
        this.data.geton_place = this.linDes.get_on_place;
        // 线路id
        this.data.route_id = this.linDes.id;
      } else {
        // 通过id 查询线路订单信息
        orderSearchById(this.$route.query.route_bn).then((res) => {
          this.data = res.data;
          this.data.sp_demand = res.data.sp_demand.split(',');
        });
      }
    },
    // 获取成人数 儿童数
    getaAdultNum() {
      this.roomJson.offered_info.forEach((item) => {
        this.data.adult_num = 0;
        this.data.children_num = 0;
        this.data.adult_num += Number(item.adultValue);
        this.data.children_num += Number(item.childValue);
      });
    },

    transferCouponValueTime() {
      // 计算截至日期
      let route_end_date;
      if (this.roomJson.date && this.linDes.travel_days) {
        var date = new Date(this.roomJson.date);
        var newDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + this.linDes.travel_days
        );
        var year2 = newDate.getFullYear();
        var month2 = newDate.getMonth() + 1;
        var day2 = newDate.getDate();
        this.data.route_end_date = new Date(year2 + '-' + month2 + '-' + day2);
      }
    },
    getLineDes() {
      // 获取线路详情
      let route_bn = '';
      if (this.$router.currentRoute.query.route_bn) {
        route_bn = this.$router.currentRoute.query.route_bn;
      } else {
        route_bn = this.roomJson.route_bn;
      }

      travelRouteDetail({ route_bn: route_bn, currency: this.currency }).then(
        (res) => {
          if (res.code == 200) {
            console.log(res.data[0]);
            this.linDes = res.data[0];
            // 计算得出线路订单信息
            this.getOrderJson();
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.travelRouteOrder {
  background: #f5f5f5;
  :deep(.el-date-editor) {
    border: none;
  }
  :deep(.el-input__inner) {
    border: none;
  }
  :deep(.el-date-editor.el-input,
  .el-date-editor.el-input__inner) {
    width: 100%;
  }

  :deep(.el-col) {
    margin-bottom: 20px;
  }

  .roomList > div > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
  }

  :deep(.line-1) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    :deep(.line-1-1) {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url('../../assets/index/icon-002.png') no-repeat;
      background-size: contain;
    }
    :deep(.line-1-3) {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url('../../assets/index/icon-003.png') no-repeat;
      background-size: contain;
    }
    :deep(.line-1-4) {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url('../../assets/index/icon-004.png') no-repeat;
      background-size: contain;
    }
  }
  :deep(.line-2) {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 24px;
  }
  :deep(.line-3) {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 0, 0, 1);
    line-height: 24px;
  }

  .button {
    background: #6f0f95;
    border-radius: 30px;
    border: 1px solid #6f0f95;
    padding: 5px 20px;
    margin: 0;
    display: inline-block;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
  }
  td {
    text-align: center;
  }
  :deep(.el-tabs) {
    margin-top: 20px;
  }

  .pay_info {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    background: #fff;
    span {
      vertical-align: middle;
    }
    a {
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3d7eff;
      line-height: 30px;
    }
  }

  .pay {
    background: rgba(111, 15, 149, 1);
    border-radius: 20px;
    padding: 5px 20px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    display: inline-block;
    margin-left: 30px;
    cursor: pointer;
  }

  .main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 500px;
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
    .left {
      width: 360px;
      background: #fff;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 359px;
        height: 59px;
        background: rgba(230, 230, 230, 1);
        padding: 0 10px;
        div:nth-child(1) {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
        }
        div:nth-child(2) {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
        }
      }
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        margin: 10px 0;

        img {
          width: 100px;
          height: 60px;
        }
        span {
          width: calc(100% - 120px);
          display: inline-block;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
        }
      }
      .date {
        font-size: 14px;
        padding: 0 10px;
        margin-top: 10px;
      }
      .go_back {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        text-decoration: underline;
        color: rgba(61, 126, 255, 1);
        line-height: 30px;
        padding: 0 10px;
        margin-top: 5px;
        cursor: pointer;
      }
      .price {
        margin: 10px 10px;
        padding-bottom: 10px;
        border-top: 1px solid #ddd;
        div:nth-child(1) {
          margin: 10px 0;
          padding-bottom: 10px;
        }
        div:nth-child(2) {
          margin: 10px 0;
          padding-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        div:nth-child(3) {
          margin: 10px 0;
          padding-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span:nth-child(2) {
            color: red;
          }
        }
      }
    }

    .right {
      width: calc(100% - 400px);
      margin-left: 40px;
      margin-bottom: 40px;
      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        span:nth-child(1) {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          line-height: 30px;
        }
        span:nth-child(2) {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 30px;
          margin-left: 10px;
        }
      }
      .top {
        background: #fff;
        padding: 40px 20px;
        input {
          margin-bottom: 40px;
        }
        .schedule {
          width: 100%;
          height: 40px;
          border: 1px solid #cccccc;
          line-height: 40px;
          padding: 0 10px;
          margin: 20px 0;
          font-size: 14px;
          color: #bababa;
          cursor: pointer;
          position: relative;

          img {
            width: 15px;
            position: absolute;
            top: 10px;
            right: 15px;
          }
          input {
            border: none;
            outline: none;
            height: 30px;
            width: calc(100% - 30px);
          }
        }
      }
      .per_info {
        padding: 40px 20px;
        background: #fff;
        margin-top: 18px;

        .prompt {
          color: #409eff;
          display: block;
          font-size: 13px;
        }

        .input_box_list {
          & > div {
            width: 30%;
          }
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .input_box_bottom {
          margin: 20px 0;
          padding-top: 10px;
          & > div {
            width: 45%;
          }
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .input_box.other {
          select {
            width: 70px;
          }
          input {
            width: calc(100% - 70px);
          }
        }

        .input_box {
          position: relative;
          width: 100%;
          border: 1px solid #ededed;
          padding: 10px;
          .label {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            line-height: 30px;
          }
          input {
            border: none;
            outline: none;
            width: 100%;
          }
          .prompt {
            position: absolute;
            font-size: 13px;
            color: red;
            width: 100%;
            left: 0;
            bottom: -20px;
          }
          :deep(.el-date-editor) {
            input {
              height: 30px;
            }
            :deep(.el-icon-date:before) {
              position: relative;
              top: -5px;
            }
          }
          select {
            border: none;
            outline: none;
            width: 100%;
          }
        }

        .phone_input_box {
          select {
            display: inline-block;
            width: 100px;
          }
          input {
            display: inline-block;
            width: calc(100% - 105px);
          }
        }

        h5 {
          margin: 20px 0;
        }
        .tourist_info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          & > div {
            width: 30%;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
</style>